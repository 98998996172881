/*----------------------------------------------------------------*/
/*  Typography
/*----------------------------------------------------------------*/

// Links
a {
    text-decoration: none;

    &:hover, &:active {
        text-decoration: underline;
    }
}

// Abbr
abbr {
    cursor: help;
    border-bottom: 1px dotted rgba(0, 0, 0, 0.54);
}

// Blockquote
blockquote {
    border-left: 3px solid rgba(0, 0, 0, 0.12);
    font-style: italic;
    margin: 1em 0;
    padding-left: 16px;

    footer {
        font-style: normal;

        &:before {
            content: '\2014 \00A0';
        }
    }

    &.reverse {
        border-left: none;
        border-right: 3px solid rgba(0, 0, 0, 0.12);
        text-align: right;
        padding-left: 0;
        padding-right: 16px;

        footer {

            &:before {
                content: '';
            }

            &:after {
                content: '\2014 \00A0';
            }
        }
    }
}

// Code
code {
    // font-family: $font-family-code;

    &:not(.highlight) {
        background: rgba(0, 0, 0, 0.065);
        color: #106CC8;
        margin: 0 1px;
        padding: 2px 3px;
        border-radius: 2px;
    }
}

// Definition lists
dl {

    dt {
        font-weight: bold;
    }

    dd {
        padding: .2rem 0;
        -webkit-margin-start: 0;
        margin-bottom: 1em;
    }
}

// Mark
mark {
    background: #F7F49A;
}

// Pre
pre {
    line-height: 1.6;
    margin: 8px 16px;
    white-space: pre-wrap;
}

// Small
small {
    font-size: 80%;
}

// Text format helpers
.text-italic {
    font-style: italic;
}

.text-semibold {
    font-weight: 600;
}

.text-bold, strong {
    font-weight: 700;
}

.text-strike {
    text-decoration: line-through;
}

.text-super {
    vertical-align: super;
}

.text-sub {
    vertical-align: sub;
}

.text-capitalize {
    text-transform: capitalize;
}

.text-lowercase {
    text-transform: lowercase;
}

.text-uppercase {
    text-transform: uppercase;
}

// Text align helpers
.text-left {
    text-align: left;
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

// Boxed text
.text-boxed {
    border-radius: 2px;
    padding: 4px 8px;
    margin: 0 8px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.54);
    background-color: rgba(0, 0, 0, 0.12);
    white-space: nowrap;
}

// Boxed text light
.text-boxed-light {
    @extend .text-boxed;
    background-color: rgba(255, 255, 255, 0.7);
}

// Truncate
.text-truncate {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

// Nowrap
.text-nowrap {
    white-space: nowrap;
}
