@use "sass:math";
// Custom Theming for Angular Material
// @use '@angular/material' as mat;

// For more information: https://material.angular.io/guide/theming
// @import '@angular/material/theming';

// Plus imports for other components in your app.
// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-typography-config());`
// @include mat.all-legacy-component-typographies();
// @include mat.legacy-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
// $DDFinance-primary: mat.define-palette(mat.$indigo-palette);
// $DDFinance-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
 //$DDFinance-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
// $DDFinance-theme: mat.define-light-theme((
//   color: (
//     primary: $DDFinance-primary,
//     accent: $DDFinance-accent,
//     warn: $DDFinance-warn,
//   )
// ));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
// @include mat.all-legacy-component-themes($DDFinance-theme);



@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";


@import "styles/custom.scss";
@import "styles/helpers.scss";
@import "styles/layout.scss";
@import "styles/typography.scss";


@media only screen and (max-width: 600px) {

    .mat-mdc-dialog-container {
        height: auto !important;
    }

    .layout-padding {
        padding: 0 !important;
    }
}


/* You can add global styles to this file, and also import other style files */

html, body {
    height: 100%;
    color: rgba(0,0,0,.87);
    background: #f1f1f1;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
    box-sizing: border-box !important;
}

a{
    color: #1d3d6d;
}


.mat-drawer-container
{
    background-color: transparent;
}

.mat-mdc-tooltip { // making the font size on the mat-tooltip 1.5rem globally
    font-size: 14px;
    background-color: #1d3d6d;
    color: #ffffff;

    &.exaggerated-tooltip { // to modify the tooltip create a class like this
        font-size: 16px; // and use it like this: *matTooltipClass="exaggerated-tooltip"* in thecolor: red;         // component in which you are putting the tooltip
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0.5rem 0;
}


.container {
    max-width: 1024px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 15px;
    padding-right: 15px;

    .container {
        max-width: inherit;
        padding-left: 0;
        padding-right: 0;
    }
}

.success {
    background-color: #4CAF50;
}

/* Green */
.info {
    background-color: #1d3d6d;
}

/* Blue */
.warning {
    background-color: #ff9800;
}

/* Orange */
.danger {
    background-color: #f44336;
}

/* Red */
.other {
    background-color: #e7e7e7;
    color: black;
}

.card {
    min-height: 100px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    border-radius: 0.3rem;
    background-color: #ffffff;
    box-shadow: 0 .1rem .2rem 0 rgba(0, 0, 0, 0.1);

    .card-head {
        flex-shrink: 0;
        padding: .5rem .5rem 0 .5rem;
        text-transform: uppercase;
       

        .card-title {
            margin: 0;

            .mat-icon {
                vertical-align: middle;
                margin-bottom: 3px;
            }
        }
    }

    .card-body {
        flex-grow: 1;
        flex-shrink: 0;
        padding: .5rem;
    }

    .card-footer {
        flex-shrink: 0;
        padding: .5rem;
    }


}

.card-bottomless { 
    min-height: 100px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    background-color: #ffffff;
    box-shadow: 0 .1rem .2rem 0 rgba(0, 0, 0, 0.1);
    border-radius: 10px 10px 0 0;

    .card-head {
        flex-shrink: 0;
        padding: .5rem .5rem 0 .5rem;
        text-transform: uppercase;
       

        .card-title {
            margin: 0;
            opacity: 0.5;

            .mat-icon {
                vertical-align: middle;
                margin-bottom: 3px;
            }
        }
    }

    .card-body {
        flex-grow: 1;
    }

}

header.card {
    padding: 1.5rem;
    border-radius: 0;
    border-right: 0;
    border-left: 0;
    border-bottom: 1px solid rgba(200, 200, 200, .3);
}

.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
    border-right-style: hidden;
}

.mat-mdc-slide-toggle.mat-checked {
    /* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.*/
    .mat-slide-toggle-bar {
        background-color: #6a83a8;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.*/
    .mat-slide-toggle-thumb {
        background-color: #1d3d6d;
    }
}

.mat-mdc-card:not([class*="mat-elevation-z"]) {
    box-shadow: none;
}

.icon-label {
    margin: 0;
    padding: 0;
    line-height: 0rem;
    font-size: .7rem;
    color: inherit;
    text-transform: uppercase;
    opacity: .5;
}

.mat-mdc-button,
.mat-mdc-outlined-button {
    text-transform: uppercase;
    text-decoration: none;
}

.table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
}

dl
{
    padding-bottom: 1rem;

    dt {
        text-transform: uppercase;
        font-weight: normal;
        color: rgba(0, 0, 0, .5);
    }

    dd {
        margin-bottom: 1.5rem;
        font-weight: normal;
    }
}

table.table {
    width: 100%;
    margin-top: 1rem;
    border-collapse: collapse;

    thead {
        th,
        td {
            padding: 1rem 0.5rem;
            border-bottom: 1px inset;
            text-transform: uppercase;
            opacity: 0.5;
        }
    }

    tbody {
        tr:hover {
            background-color: rgba(0, 0, 0, .02);
        }

        tr:last-child td {
            border-bottom: 0px;
        }

        td {
            padding: 0.5rem;
            border-bottom: 1px inset;
        }
    }

    tfoot
    {
        font-weight: bold;

        td{
            padding: 1rem 0.5rem;
            border-top: 1px solid rgba(200, 200, 200, .3);
            text-transform: uppercase;
        }
    }
}

.selected {
    background-color: #d8e6cc !important;
    color: #3b5524 !important;
}

.header
{
    margin: .5rem 0 1rem 0;
    text-align: left;
    text-transform: uppercase;
    font-size: 1.2rem;
    font-weight: normal;
}

.btn
{
    &.text-primary
    {
        color: #1d3d6d;
    }

    &.is-primary
    {
        display: inline-block;
        background-color: #1d3d6d;
        color: #fff;
        text-align: center;
        text-transform: uppercase;
        text-decoration: none;
    }

    &.is-black {
        background-color: #444444;
    }

    &.is-green {
        background-color: #9dd753;
        color: #fff;
        text-shadow: 1px 1px 1px #bbb;
    }

    &.is-orange {
        background-color: #FFB300;
    }

    &.is-red {
        background-color: #E33244;
    }
}

.ddf-snackbar-panel
{
    background-color: #1d3d6d;
    color: #fff;
}

.label {
    display: inline-block;
    min-width: 5rem;
    padding: .2rem .4rem;
    text-align: center;
    border-radius: .2rem;
    color: #fff;

    &.is-black {
        background-color: #444;
    }

    &.is-green {
        background-color: #92CF5C;
    }

    &.is-orange {
        background-color: #FFB300;
    }

    &.is-red {
        background-color: #E33244;
    }
}

.message {
    margin: .5rem 0;
    padding: .5rem;
    border-radius: .2rem;
    white-space: pre-wrap;
    text-align: center;

    &.is-info {
        color: #00529B !important;
        background-color: #BDE5F8 !important;
    }

    &.is-success {
        color: #4F8A10 !important;
        background-color: #DFF2BF !important;
    }

    &.is-warning {
        color: #9F6000 !important;
        background-color: #FEEFB3 !important;
    }

    &.is-error {
        color: #D8000C !important;
        background-color: #FFD2D2 !important;
    }
}

.spin {
    animation-name: spin;
    animation-duration: 1000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

.separator {
    font-size: 15px;
    font-weight: 600;
    margin: 24px auto;
    position: relative;
    overflow: hidden;
    width: 100px;
    text-align: center;

    .text {
        display: inline-flex;
        position: relative;
        padding: 0 8px;
        z-index: 9999;

        &:before,
        &:after {
            content: '';
            display: block;
            width: 30px;
            position: absolute;
            top: 10px;
            border-top: 1px solid;
        }

        &:before {
            right: 100%;
        }

        &:after {
            left: 100%;
        }
    }
}

.page
{
    background: #1d3d6d; /* Old browsers */
    // background: -moz-linear-gradient(top,  #1d3d6d 0%, #1d3d6d 26%, #ffffff 26%, #ffffff 100%); __<<ngThemingMigrationEscapedComment6>>__
    // background: -webkit-linear-gradient(top,  #1d3d6d 0%,#1d3d6d 26%,#ffffff 26%,#ffffff 100%); __<<ngThemingMigrationEscapedComment7>>__
    background: linear-gradient(to bottom,  #1d3d6d 0,#1d3d6d 125px,#f1f1f1 125px,#f1f1f1 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1d3d6d', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */
    
    h1, h2, h3, h4, h5
    {
        text-transform: uppercase;

        &.inverse
        {
            color: #f1f1f1;
        }
    }

    .page-header
    {
        min-height: 5rem;
    }
}

.title{
    margin: 15px !important;
    padding: 0 !important;
    text-transform: uppercase;
    font-weight: normal !important;
    line-height: 1rem !important;

    .super,
    .sub
    {
        margin: 0 !important;
        padding: 0 !important;
        font-size: .6rem;
        display: block;
    }

    .mat-icon {
        margin-right: 5px;
        margin-bottom: 5px;
        vertical-align: middle;
    }
}

.cursor-pointer
{
    cursor: pointer;
}

.input-search
{
    width: 100%;
    box-sizing: border-box;
    padding: .5rem .75rem;
    border: 1px solid #ccc;
    border-radius: 1rem;
    background-color: #fff;
    outline: none;
    font-size: 1rem;

    &:focus
    {
        outline: none;
    }
}

#sidenav
{
    min-width: 20%;
    max-width: 60%;
    background-color: #ffffff;
    box-sizing: border-box !important;
}

#wrapper
{
    #sidenav
    {
        background-color: #1d3d6d;
        color: #f1f1f1;

        a{
            color: #ffffff;
        }

        &.is-xs
        {
            min-width: 60%;
            max-width: 80%;
        }
    }
}

.custom-stepper .mat-vertical-content-container{
    margin-top: 20px;
    margin-bottom: 20px;
  }

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
