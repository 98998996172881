.background-map-img {
  min-height: 200px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* make scrollbar transparent */
}

.avatar {
  background: #1d3d6d;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #0e1e36, #1d3d6d);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #0e1e36, #1d3d6d); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
